<div class="container footer text-muted">
  <div class="row">
    <div class="col">Glints Password Manager</div>
    <div class="col-8 text-center">
      A modified version of the Bitwarden&reg; Web Vault for Vaultwarden
    </div>
    <div class="col text-right">
      {{ "versionNumber" | i18n: version }}
    </div>
  </div>
</div>
