<router-outlet></router-outlet>
<div class="container my-5 text-muted text-center">
  <environment-selector></environment-selector>
  Glints Password Manager<br />
  {{ "versionNumber" | i18n: version }}
  <br /><br />
  <div class="small">
    A modified version of the Bitwarden&reg; Web Vault for Vaultwarden (an unofficial rewrite of the
    Bitwarden&reg; server).<br /></div>
</div>
